<template>
  <div class="d-flex align-start justify-center cardforgraph" id="graphColab">
    <apexCharts
      v-if="serie.length > 0"
      class="apexchart"
      style="min-height: 80px !important"
      width="100%"
      height="111px"
      :options="active ? chartOptions : serie.length >= 2 ? chartOptions2 : chartOptions3"
      :series="graphSerie"
      type="donut"
    />
  </div>
</template>

<script>
import ThemeMixin from '@/mixins/Theme.vue';
import 'dayjs/locale/pt-br';
let primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--v-primary-base');
export default {
  name: 'DonutForFilter',
  props: ['serie', 'active'],
  components: {
    ApexCharts: () => import('vue-apexcharts'),
    NoData: () => import('../../indicadores/components/NoData.vue'),
  },

  mixins: [ThemeMixin],

  data() {
    return {
      chartOptions: {
        dataLabels: {
          enabled: false,
        },
        selection: {
          enabled: false,
          type: 'x',
        },
        states: {
          active: {
            filter: {
              type: 'none',
            },
          },
          normal: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          hover: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
        },
        plotOptions: {
          pie: {
            customScale: 0.98868,
          },
        },
        tooltip: {
          enabled: false,
        },
        stroke: {
          colors: ['#E7E7FA'],
        },

        legend: {
          show: false,
          position: 'top',
          showForSingleSeries: false,
          showForZeroSeries: true,
          horizontalAlign: 'center',
          markers: {
            width: 12,
            height: 12,
          },
          labels: {
            show: false,
            colors: '#575767',
          },
        },
        colors: [primaryColor, '#F7F7FD'],
      },
      chartOptions2: {
        dataLabels: {
          enabled: false,
        },
        selection: { enabled: false },
        states: {
          active: {
            filter: {
              type: 'none',
            },
          },
          normal: {
            filter: {
              type: 'none',
            },
          },
          hover: {
            filter: {
              type: 'none',
            },
          },
        },
        plotOptions: {
          pie: {
            customScale: 0.98868,
          },
        },
        tooltip: {
          enabled: false,
        },
        stroke: {
          colors: ['#E7E7FA'],
        },

        legend: {
          show: false,
          position: 'top',
          showForSingleSeries: false,
          showForZeroSeries: true,
          horizontalAlign: 'center',
          markers: {
            width: 12,
            height: 12,
          },
          labels: {
            show: false,
            colors: '#575767',
          },
        },
        colors: [primaryColor, '#E7E7FA'],
      },
      chartOptions3: {
        dataLabels: {
          enabled: false,
        },
        selection: { enabled: false },
        states: {
          active: {
            filter: {
              type: 'none',
            },
          },
          normal: {
            filter: {
              type: 'none',
            },
          },
          hover: {
            filter: {
              type: 'none',
            },
          },
        },
        plotOptions: {
          pie: {
            customScale: 0.98868,
          },
        },
        tooltip: {
          enabled: false,
        },
        stroke: {
          colors: ['#e7e7fa'],
        },

        legend: {
          show: false,
          position: 'top',
          showForSingleSeries: false,
          showForZeroSeries: true,
          horizontalAlign: 'center',
          markers: {
            width: 12,
            height: 12,
          },
          labels: {
            show: false,
            colors: '#575767',
          },
        },
        colors: ['#e7e7fa'],
      },
    };
  },
  computed: {graphSerie(){
    let serieAltered = this.serie;
    serieAltered[1]= serieAltered[1]-serieAltered[0];

    return serieAltered;
  }},
  methods: {},
};
</script>
<style scoped>
::v-deep .cardforgraph {
  height: 80px !important;
  width: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important;
}
</style>
