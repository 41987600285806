var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex align-start justify-center cardforgraph",
      attrs: { id: "graphColab" },
    },
    [
      _vm.serie.length > 0
        ? _c("apexCharts", {
            staticClass: "apexchart",
            staticStyle: { "min-height": "80px !important" },
            attrs: {
              width: "100%",
              height: "111px",
              options: _vm.active
                ? _vm.chartOptions
                : _vm.serie.length >= 2
                ? _vm.chartOptions2
                : _vm.chartOptions3,
              series: _vm.graphSerie,
              type: "donut",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }